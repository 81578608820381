import { Typography } from '@org-crowley/enterprise-react-component-library';

interface Props {
  errorMessage?: string;
}

export function ErrorMessage({
  errorMessage = 'We’re having trouble getting this information, please try again later.'
}: Props) {
  return (
    <div className="flex items-center justify-center flex-col w-[300px] text-center">
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.012 46.3343H51.971C52.6313 46.3343 53.285 46.4645 53.8949 46.7171C54.5048 46.9697 55.0592 47.3399 55.526 47.8067C55.9928 48.2735 56.363 48.8279 56.6156 49.4378C56.8685 50.0477 56.9984 50.7014 56.9984 51.3617C56.9984 52.0217 56.8685 52.6754 56.6156 53.2856C56.363 53.8955 55.9928 54.4496 55.526 54.9164C55.0592 55.3832 54.5048 55.7534 53.8949 56.0063C53.285 56.2589 52.6313 56.3888 51.971 56.3888H8.02566C6.69234 56.3888 5.41365 55.8593 4.47087 54.9164C3.52806 53.9735 2.99841 52.6949 2.99841 51.3617V50.9897C2.99841 49.6565 3.52806 48.3776 4.47087 47.435C5.41365 46.4921 6.69234 45.9623 8.02566 45.9623H11.1932L26.7246 5.41337C26.9962 4.70693 27.4744 4.09877 28.0969 3.66824C28.7194 3.23771 29.4572 3.00482 30.2141 2.99999C30.9707 2.99512 31.7117 3.21851 32.3396 3.64103C32.9675 4.06352 33.4535 4.6655 33.734 5.3684L46.4984 38.9999"
          stroke="#F2A716"
          strokeWidth="4.35"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.3707 19.7916H38.883"
          stroke="#F2A716"
          strokeWidth="4.35"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.1458 33.3121H43.218"
          stroke="#F2A716"
          strokeWidth="4.35"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <Typography variant="h500" as="h5" className="my-4">
        We encountered an unexpected problem
      </Typography>
      <Typography variant="body-small">{errorMessage}</Typography>
    </div>
  );
}
